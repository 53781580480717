import { gql, useQuery } from '@apollo/client';

export const ALL_PRODUCTS_QUERY = gql`
  query AllProductsQuery {
    allProduct(where: { isPublished: { eq: true } }, sort: { order: ASC, _updatedAt: DESC }) {
      _id
      name {
        en
        de
      }
      description {
        en
        de
      }
      logo {
        asset {
          url
        }
      }
      url
    }
  }
`;

/**
 * Hook to list all the products of Integrtr.
 */
export function useAllProducts() {
  const { data, ...rest } = useQuery(ALL_PRODUCTS_QUERY);
  return {
    products: data?.allProduct ?? [],
    ...rest,
  };
}

export const GET_PRODUCT_QUERY = gql`
  query GetProductQuery($id: ID!) {
    Product(id: $id) {
      _id
      name {
        en
        de
      }
      description {
        en
        de
      }
      logo {
        asset {
          url
        }
      }
      url
    }
  }
`;

/**
 * Hook to get a product.
 */
export function useProduct({ productId }) {
  const { data, ...rest } = useQuery(GET_PRODUCT_QUERY, {
    variables: {
      id: productId,
    },
    skip: !productId,
  });

  return { product: data?.Product, ...rest };
}
