import { Heading } from '@chakra-ui/react';
import { Language, useLanguage } from 'store/language';

export const getLocaleText = (text, language) => {
  let lang = useLanguage.get();
  if (language) {
    lang = language;
  }

  let formattedText;
  switch (lang) {
    case Language.GERMAN:
      formattedText = text?.de;
      break;

    default:
  }

  return formattedText ?? text?.en;
};

/**
 * Show the text in a given language based on the user preference.
 */
export default function LocaleText({ text, isHeading, ...rest }) {
  const language = useLanguage();
  const localeText = getLocaleText(text, language) ?? null;

  if (isHeading) {
    return <Heading {...rest}>{localeText}</Heading>;
  }

  return localeText;
}
