import Head from 'next/head';

import config from 'lib/config';
import useLocalizedRouter from './hooks/useLocalizedRouter';

function getCurrentPageUrl(path) {
  return `https://help.integrtr.com${path}`;
}

function getCurrentPageTitle(title) {
  return title ? `INTEGRTR Help | ${title}` : 'INTEGRTR Help';
}

export default function Meta({ title, description, url, imagePath = '/brand.png' }) {
  const { asPath } = useLocalizedRouter();

  return config.SANITY_DATASET === 'production' ? (
    <Head>
      {/* Primary Meta Tags */}
      <title>{getCurrentPageTitle(title)}</title>
      <meta name="robots" content="max-snippet:-1" />
      <meta name="title" content={getCurrentPageTitle(title)} />
      <meta name="description" content={description} />
      <meta name="google-site-verification" content="ukzx0bjpKVCs8y9DOwyEM6vVSvBl-V7hbTvCy6MpGSI" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url ?? getCurrentPageUrl(asPath)} />
      <meta property="og:title" content={getCurrentPageTitle(title)} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imagePath} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url ?? getCurrentPageUrl(asPath)} />
      <meta property="twitter:title" content={getCurrentPageTitle(title)} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imagePath} />
    </Head>
  ) : (
    <meta name="robots" content="noindex nofollow" />
  );
}
