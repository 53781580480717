const get = (obj, accessor) => {
  if (accessor) {
    if (typeof accessor === 'function') {
      return accessor(obj);
    } else if (typeof accessor === 'string') {
      return obj[accessor];
    }
  }
  return null;
};

export class TreeMaker {
  /**
   * @param {object} config
   * @param {string | (object) => string} [config.id] id accessor for a node
   * @param {string | (object) => string} [config.parent] parent accessor for a node
   * @param {string} [config.children]  property name to use for children
   */
  constructor({ id = 'id', parent = 'parent', children = 'children' } = {}) {
    this.config = {
      id,
      parent,
      children,
    };
  }

  /**
   * Convert a hierarchy from flat to nested representation.
   *
   * @param {array} flat The array with the hierachy flat representation.
   */
  convert(flat) {
    let i;
    let len;
    let temp;
    let roots;
    let id;
    let parent;
    let nested;
    let pendingChildOf;
    let flatEl;
    i = 0;
    roots = [];
    temp = {};
    pendingChildOf = {};

    for (i, len = flat.length; i < len; i++) {
      flatEl = flat[i];
      id = flatEl[this.config.id];
      id = get(flatEl, this.config.id);
      parent = get(flatEl, this.config.parent);
      temp[id] = flatEl;
      if (parent === undefined || parent === null) {
        // Current object has no parent, so it's a root element.
        roots.push(flatEl);
      } else {
        if (temp[parent] !== undefined) {
          // Parent is already in temp, adding the current object to its children array.
          initPush(this.config.children, temp[parent], flatEl);
        } else {
          // Parent for this object is not yet in temp, adding it to pendingChildOf.
          initPush(parent, pendingChildOf, flatEl);
        }
      }
      if (pendingChildOf[id] !== undefined) {
        // Current object has children pending for it. Adding these to the object.
        multiInitPush(this.config.children, flatEl, pendingChildOf[id]);
      }
    }

    if (roots.length === 1) {
      nested = roots[0];
    } else if (roots.length > 1) {
      nested = {};
      nested[this.config.children] = roots;
    } else {
      nested = {};
    }
    return nested;
  }
}

function initPush(arrayName, obj, toPush) {
  if (obj[arrayName] === undefined) {
    obj[arrayName] = [];
  }
  obj[arrayName].push(toPush);
}

function multiInitPush(arrayName, obj, toPushArray) {
  let len;
  len = toPushArray.length;
  if (obj[arrayName] === undefined) {
    obj[arrayName] = [];
  }
  while (len-- > 0) {
    obj[arrayName].push(toPushArray.shift());
  }
}
