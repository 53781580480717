import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import config from './config';
import useLocalizedRouter from 'components/hooks/useLocalizedRouter';

export const PAGE_TYPE = {
  HOME: 'Home',
  PRODUCT: 'Product',
  DOCUMENTATION: 'Documentation',
  PRODUCT_FAQ: 'Product FAQ',
};

export const EVENT_TYPE = {
  DOCUMENTATION_PDF_DOWNLOAD: 'Download Documentation PDF',
};

const message = 'Page Visited';
export const useSentryPageEvent = (type) => {
  const router = useLocalizedRouter();

  useEffect(() => {
    if (config.NODE_ENV === 'production' && router.isReady) {
      Sentry.captureEvent({
        message,
        level: 'info',
        environment: config.SANITY_DATASET,
        extra: {
          type,
          pathname: router.pathname,
          asPath: router.asPath,
          ...(router.query?.product && {
            product: router.query.product,
          }),
          ...(router.query?.documentation && {
            documentation: router.query.documentation,
          }),
        },
      });
    }
  }, [router, type]);
};

export const useSentryContext = (user) => {
  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    } else {
      Sentry.setUser(null);
    }
  }, [user]);
};

export const captureAdhocEvent = (payload) => {
  if (config.NODE_ENV === 'production') {
    Sentry.captureEvent({
      level: 'info',
      environment: config.SANITY_DATASET,
      ...payload,
    });
  }
};
