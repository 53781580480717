import { gql, useQuery } from '@apollo/client';
import { TreeMaker } from 'lib/TreeMaker';
import { useMemo } from 'react';
import TreeModel from 'tree-model';

import { useWhoAmI } from './user';

export const GET_DOCUMENTATION_QUERY = gql`
  query GetDocumentationQuery($id: ID!) {
    Documentation(id: $id) {
      _id
      title {
        en
        de
      }
      product {
        _id
        name {
          en
          de
        }
      }
      subtitle {
        en
        de
      }
      content {
        enRaw
        deRaw
      }
      isPublished
      isPrivate
    }
  }
`;

/**
 * Hook to get a documentation for a product.
 */
export function useDocumentationOfProduct({ documentationId, productId }) {
  const { data, ...rest } = useQuery(GET_DOCUMENTATION_QUERY, {
    variables: {
      id: documentationId,
    },
  });

  return {
    documentation:
      data?.Documentation?.product?._id === productId && data?.Documentation?.isPublished
        ? data?.Documentation
        : null,
    isPrivate: data?.Documentation?.isPrivate,
    ...rest,
  };
}

export const allDocumentationsOfProductQuery = () => gql`
  query GetAllDocumentationsOfProduct($id: ID!) {
    allDocumentation(
      where: { product: { _id: { eq: $id } }, isPublished: { eq: true } }
      sort: { order: ASC, _updatedAt: DESC }
    ) {
      _id
      title {
        en
        de
      }
      parentTopic {
        _id
      }
    }
  }
`;

/**
 * Hook to get the titles of all the documentations for a product.
 */
export function useAllDocumentationsOfProductForSidebar({ productId }) {
  const { data, ...rest } = useQuery(allDocumentationsOfProductQuery(), {
    variables: {
      id: productId,
    },
  });

  // Add additional meta for the documentations to define which documentations are the root.
  const documentations = useMemo(
    () =>
      (data?.allDocumentation ?? []).map((it) => ({
        ...it,
        isRoot: !it.parentTopic,
      })),
    [data?.allDocumentation]
  );

  return {
    documentations: documentations,
    ...rest,
  };
}

export const allDocumentationContentOfProductQuery = (isLoggedIn) => gql`
  query GetAllDocumentationsOfProduct($id: ID!) {
    allDocumentation(where: { product: { _id: { eq: $id } }, isPublished: { eq: true } ${
      !isLoggedIn ? ', isPrivate: {neq: true}' : ''
    } }, sort: { order: ASC, _updatedAt: DESC }) {
      _id
      order
      title {
        en
        de
      }
      parentTopic {
        _id
      }
      content {
        enRaw
      }
    }
  }
`;

export const useAllDocumentationsOfProductForPDF = ({ productId }) => {
  const user = useWhoAmI();
  const { data } = useQuery(allDocumentationContentOfProductQuery(!!user), {
    variables: {
      id: productId,
    },
  });

  const docs = useMemo(() => {
    const treeMaker = new TreeMaker({ id: '_id', parent: (el) => el.parentTopic?._id });
    const rawTree = treeMaker.convert(data?.allDocumentation?.map((doc) => ({ ...doc })) ?? []);
    rawTree.id = 'root';

    const treeModel = new TreeModel();
    const root = treeModel.parse(rawTree);

    const docs = [];
    root.walk(({ model: { children, ...model } }) => {
      if (model.id !== 'root') {
        docs.push(model);
      }
    });

    return docs;
  }, [data?.allDocumentation]);

  return {
    documentations: docs,
  };
};

export const rootDocumentationsOfProductQuery = () => gql`
  query RootDocumentationsofProductQuery($id: ID!) {
    allDocumentation(
      where: { product: { _id: { eq: $id } }, isPublished: { eq: true } }
      sort: { order: ASC, _updatedAt: DESC }
    ) {
      _id
      title {
        en
        de
      }
      subtitle {
        en
        de
      }
      thumbnail {
        asset {
          url
        }
      }
      product {
        _id
        name {
          en
          de
        }
      }
      parentTopic {
        _id
      }
    }
  }
`;

/**
 * Hook to get all the root documentation for the product page.
 */
export function useRootDocumentationsForProductPage({ productId }) {
  const { data, ...rest } = useQuery(rootDocumentationsOfProductQuery(), {
    variables: {
      id: productId,
    },
  });

  // Only get the root documentations which are not in nested doc ids.
  const documentations = useMemo(
    () => (data?.allDocumentation ?? []).filter((it) => !it.parentTopic),
    [data?.allDocumentation]
  );

  return {
    documentations,
    ...rest,
  };
}

export const documentationSearchQuery = () => gql`
  query DocumentationsSearchQuery($search: String!) {
    allDocumentation(
      where: { title: { en: { matches: $search } }, isPublished: { eq: true } }
      limit: 12
    ) {
      _id
      title {
        en
        de
      }
      product {
        _id
        name {
          en
          de
        }
      }
      subtitle {
        en
        de
      }
      isPrivate
    }
  }
`;

export const productionDocumentationSearchQuery = (i) => gql`
  query DocumenationsSearchQuery($search: String!, $productId: ID!) {
    allDocumentation(
      where: {
        title: { en: { matches: $search } }
        product: { _id: { eq: $productId } }
        isPublished: { eq: true }
      }
      limit: 12
    ) {
      _id
      title {
        en
        de
      }
      product {
        _id
        name {
          en
          de
        }
      }
      subtitle {
        en
        de
      }
      isPrivate
    }
  }
`;

/**
 * Hook to get documentations from search.
 */
export function useDocumentationsFromSearch({ search, productId }) {
  const { data: allData, ...allRest } = useQuery(documentationSearchQuery(), {
    variables: {
      search,
    },
    // Only search this when search text is only provided.
    skip: !search || !!productId,
  });

  const { data: productData, ...productRest } = useQuery(productionDocumentationSearchQuery(), {
    variables: {
      search,
      productId,
    },
    // Only search this when search and product are both provided.
    skip: !search || !productId,
  });

  if (productId) {
    return {
      documentations: productData?.allDocumentation ?? [],
      ...productRest,
    };
  }

  return {
    documentations: allData?.allDocumentation ?? [],
    ...allRest,
  };
}
