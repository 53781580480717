import { Box, Flex } from '@chakra-ui/react';

import { Footer } from './Footer';
import Navigation from './Navigation';

export default function Layout({ children }) {
  return (
    <Flex minHeight="full" flexDirection="column" justifyContent="flex-start">
      <Navigation />
      <Box flex="1 0 auto">{children}</Box>
      <Footer />
    </Flex>
  );
}
