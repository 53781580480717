import {
  Avatar,
  Box,
  Button,
  Container,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import config from 'lib/config';
import { useSentryContext } from 'lib/eventLogger';
import Link from 'next/link';
import { useContext } from 'react';
import { MdArrowDropDown, MdExitToApp, MdLanguage, MdChatBubbleOutline } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { Language, useLanguage, useLanguageSetter } from 'store/language';
import { useLogout, WhoAmIContext } from 'store/user';

import SearchFullScreen from './SearchFullScreen';
import useLocalizedRouter from './hooks/useLocalizedRouter';

export default function Navigation() {
  const router = useLocalizedRouter();
  const language = useLanguage();
  const setLanguage = useLanguageSetter();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  useEffect(() => {
    const selectedLanguage = router.query.lang;
    const _language = selectedLanguage || language;

    setLanguage(_language);

    router.query = { ...router.query, lang: _language };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLanguage]);

  const user = useContext(WhoAmIContext);
  useSentryContext(user);

  return (
    <Box
      zIndex={isDrawerOpen ? 'hide' : 'popover'}
      py={3}
      px={[2, null, null, 0]}
      shadow="base"
      position="sticky"
      top={0}
      bg="white"
    >
      <Container maxW="6xl" display="flex" alignItems="center" justifyContent="space-between">
        <Link href="/" passHref>
          <Box as="a" display="flex" alignItems="flex-end" mt={-1}>
            <Box display={['flex', null, 'flex']} alignItems="flex-end">
              <Box as="img" src="/logo_dark.png" alt="INTEGRTR Logo" height={[6, null, 8]} />
              <Heading
                display={['none', 'block']}
                as="h1"
                color="#515151"
                fontSize={['md', null, 'xl']}
                fontWeight="700"
                ml={2}
              >
                <FormattedMessage id="HELP" />
              </Heading>
            </Box>
          </Box>
        </Link>

        <Box maxWidth={400} flex={1} mx={4} display={['none', null, 'block']}>
          <SearchFullScreen isOpen={isDrawerOpen} onOpen={onDrawerOpen} onClose={onDrawerClose} />
        </Box>

        <Box display="flex" alignItems="center">
          <Box display={['inline-block', null, 'none']} mr={[2, null, 3]}>
            <SearchFullScreen
              isOpen={isDrawerOpen}
              onOpen={onDrawerOpen}
              onClose={onDrawerClose}
              iconOnly
            />
          </Box>

          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              mr={[2, null, 3]}
              variant="ghost"
              color="#354a5f"
              sx={{
                '&:hover': {
                  bg: 'rgba(53,74,95,0.1)',
                },
              }}
              display="none"
            >
              <Box display="flex" alignItems="center">
                <MdLanguage /> <Box ml={1}>{language}</Box>
              </Box>
            </MenuButton>
            <MenuList minWidth={128}>
              <MenuItem onClick={() => setLanguage(Language.ENGLISH)}>
                <FormattedMessage id="ENGLISH" />
              </MenuItem>
              <MenuItem onClick={() => setLanguage(Language.GERMAN)} disabled>
                <FormattedMessage id="GERMAN" />
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              rightIcon={<MdArrowDropDown />}
              size="sm"
              display={['inline-flex', null, 'none']}
            >
              <FormattedMessage id="SUPPORT" />
            </MenuButton>

            <MenuList>
              {!user && (
                <MenuItem
                  onClick={() =>
                    router.push(
                      config.PLATFORM_BASE_URL +
                        `/login?redirectTo=${encodeURIComponent(window.location.href)}`
                    )
                  }
                >
                  <FormattedMessage id="LOGIN" />
                </MenuItem>
              )}

              <MenuItem>
                <FormattedMessage id="CONTACT" />
              </MenuItem>
              <MenuItem>
                <FormattedMessage id="ABOUT_US" />
              </MenuItem>
              <MenuItem onClick={() => router.push(`/all-faqs`)}>
                <FormattedMessage id="FAQS" />
              </MenuItem>

              <MenuDivider />
              <MenuGroup title="Language">
                <MenuItem onClick={() => setLanguage(Language.ENGLISH)}>
                  <FormattedMessage id="ENGLISH" />
                </MenuItem>
                <MenuItem onClick={() => setLanguage(Language.GERMAN)}>
                  <FormattedMessage id="GERMAN" />
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>

          <Link href="/all-faqs" passHref>
            <Button
              display={['none', null, 'flex']}
              sx={{ marginRight: 3 }}
              leftIcon={<MdChatBubbleOutline />}
            >
              <FormattedMessage id="FAQS" />
            </Button>
          </Link>

          {user && <UserMenu user={user} />}

          {!user && (
            <Button
              color="white"
              bg="#354a5f"
              sx={{
                '&:hover': {
                  bg: '#2e3e4e',
                },
              }}
              display={['none', null, 'flex']}
              onClick={() =>
                router.push(
                  config.PLATFORM_BASE_URL +
                    `/login?redirectTo=${encodeURIComponent(window.location.href)}`
                )
              }
            >
              <FormattedMessage id="LOGIN" />
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}

function UserMenu({ user }) {
  const { logout } = useLogout();

  return (
    <Menu placement="bottom-end" ml={[2, null, 0]}>
      <MenuButton>
        <Avatar src={user.picture} height="32px" width="32px" />
      </MenuButton>
      <MenuList minWidth={160}>
        <MenuItem onClick={logout}>
          <Box mr={2}>
            <MdExitToApp />
          </Box>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
