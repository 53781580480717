import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { MdClose, MdSearch } from 'react-icons/md';
import { BiLockAlt } from 'react-icons/bi';

import LocaleText, { getLocaleText } from 'components/LocaleText';
import { slugify } from 'lib/utils';
import { useDocumentationsFromSearch } from 'store/documentations';
import { useProduct } from 'store/products';
import { useLanguage } from 'store/language';
import SearchSvg from './SearchSvg';
import NoSearchResultSvg from './NoSearchResultSvg';
import useLocalizedRouter from './hooks/useLocalizedRouter';

function getOriginalPath() {
  if (typeof window !== 'undefined') {
    const { origin, pathname } = window.location;
    return `${origin}${pathname}`;
  }

  return '';
}

export default function SearchFullScreen({ isOpen, onOpen, onClose, iconOnly }) {
  const { query, replace } = useLocalizedRouter();
  const productId = query?.product?.split?.('--')?.[1];
  const searchQuery = query?.search;

  const [search, setSearch] = useState(searchQuery ?? '');
  const language = useLanguage();

  useEffect(() => {
    // Open drawer only when search query is present on initial load
    if (search) {
      onOpen();
    }
  }, [onOpen, search]);

  const onSearchInput = useCallback(
    (event) => {
      const value = event.target.value;
      const queryString = value ? `?search=${value}` : getOriginalPath();

      replace(queryString, undefined, { shallow: true });
      setSearch(value);
    },
    [setSearch, replace]
  );

  const { documentations, loading } = useDocumentationsFromSearch({ search, productId });
  const { product } = useProduct({ productId });
  const productText = getLocaleText(product?.name, language);

  return (
    <>
      {!iconOnly && (
        <InputGroup borderColor="rgba(0,0,0,0.2)">
          <FormattedMessage
            id={productText ? 'SEARCH_PLACEHOLDER_PROJECT' : 'SEARCH_PLACEHOLDER_ALL'}
            values={{ product: productText }}
          >
            {(message) => <Input placeholder={message} onClick={onOpen} />}
          </FormattedMessage>
          <InputRightElement>
            <Box fontSize="xl" color="gray.500">
              <MdSearch />
            </Box>
          </InputRightElement>
        </InputGroup>
      )}

      {iconOnly && (
        <IconButton fontSize="xl" variant="ghost" onClick={onOpen}>
          <MdSearch />
        </IconButton>
      )}

      <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="top">
        <DrawerOverlay zIndex={2}>
          <DrawerContent>
            <DrawerHeader>
              <Box display="flex" justifyContent="space-between">
                <Box width={10} display={['none', null, 'block']} />

                <Box maxWidth={440} flex={1}>
                  <InputGroup>
                    <FormattedMessage
                      id={productText ? 'SEARCH_PLACEHOLDER_PROJECT' : 'SEARCH_PLACEHOLDER_ALL'}
                      values={{ product: productText }}
                    >
                      {(message) => (
                        <Input
                          placeholder={message}
                          onClick={onOpen}
                          onChange={onSearchInput}
                          value={search}
                          px={[4, null, 6]}
                          py={6}
                        />
                      )}
                    </FormattedMessage>
                    <InputRightElement>
                      <Box fontSize="2xl" color="gray.500" mt={2}>
                        <MdSearch />
                      </Box>
                    </InputRightElement>
                  </InputGroup>
                </Box>

                <IconButton onClick={onClose} height={50} ml={[2, null, 0]}>
                  <MdClose />
                </IconButton>
              </Box>
            </DrawerHeader>

            <DrawerBody>
              {!search && (
                <>
                  <Box maxWidth={450} px={8} mx="auto" mt={16}>
                    <SearchSvg />
                  </Box>

                  <Text textAlign="center" fontSize="lg" color="gray.500" mt={8}>
                    <FormattedMessage id="SEARCH_HELP_TEXT" />
                  </Text>
                </>
              )}

              {search && !loading && documentations.length === 0 && (
                <>
                  <Box maxWidth={400} px={8} mx="auto" mt={16}>
                    <NoSearchResultSvg />
                  </Box>

                  <Text textAlign="center" color="gray.500" mt={8}>
                    <FormattedMessage id="SEARCH_NO_RESULT" />
                  </Text>
                </>
              )}

              <Box maxWidth={600} mx="auto">
                {loading &&
                  Array(6)
                    .fill(0)
                    .map((_, index) => <Skeleton key={index} height={80} mb={4} />)}

                {!loading &&
                  documentations.map((it, index) => (
                    <Link
                      key={index}
                      href={`/${slugify(it.product.name.en)}--${it.product._id}/${slugify(
                        it.title.en
                      )}--${it._id}`}
                      passHref
                    >
                      <a onClick={onClose}>
                        <Box
                          key={it._id}
                          mt={6}
                          px={2}
                          py={2}
                          sx={{
                            transition: 'all 200ms ease-in-out',
                            '&:hover': {
                              bg: 'gray.100',
                            },
                          }}
                        >
                          <Text fontSize="lg" fontWeight="semibold" color="gray.700">
                            <LocaleText text={it.title} />
                          </Text>
                          <Text color="gray.500">
                            <LocaleText text={it.subtitle} />
                          </Text>
                          <Box>
                            <Tag mt={2}>
                              <LocaleText text={it.product.name} />
                            </Tag>
                            {it.isPrivate && (
                              <Tag ml={1} mt={2}>
                                <TagLeftIcon boxSize="12px" as={BiLockAlt} />
                                <TagLabel>Private</TagLabel>
                              </Tag>
                            )}
                          </Box>
                        </Box>
                      </a>
                    </Link>
                  ))}
              </Box>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}
