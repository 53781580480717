import { Box, Link, Text } from '@chakra-ui/layout';

export function Footer() {
  return (
    <Box
      flexShrink={0}
      bg="#354a5f"
      p={5}
      width="100%"
      height={[180, null, 120, 90]}
      display="flex"
      alignItems="flex-end"
    >
      <Box>
        <Text fontWeight="medium" textAlign="left" color="white" fontSize="0.75rem">
          Copyright © {new Date().getFullYear()} INTEGRTR. All rights reserved. No part of
          publications on{' '}
          <Link color="blue.400" href="https://help.integrtr.com/">
            help.integrtr.com
          </Link>
          , may be reproduced, changed or transmitted in any form or for any purpose without the
          express written permission of INTEGRTR GmbH or Cloudintegrtr Software Services Pvt Ltd.{' '}
          <br />
        </Text>
        <Link
          fontSize="0.75rem"
          color="white"
          href="https://www.integrtr.com/impressum/"
          textDecoration="underline"
        >
          Imprint/Impressum
        </Link>
      </Box>
    </Box>
  );
}
