import slug from 'slugify';
import imageUrlBuilder from '@sanity/image-url';

import config from './config';

const builder = imageUrlBuilder({
  projectId: 'yzi5x4ah',
  dataset: config.SANITY_DATASET,
});

export function imageUrlFor(source) {
  return builder.image(source);
}

export function getVersionString(version) {
  return `v${version}`;
}

/**
 * Converts a string to a slug which can be used in a URL.
 */
export function slugify(text) {
  // Slugify the text and remove any double dashes from the slug as well as any trailing dashes.
  // Why? Because we use double dashes to separate slug text and a UUID.
  return slug(text, {
    replacement: '-',
    lower: true,
    strict: true,
  })
    .replace(/-+/, '-')
    .replace(/-$/, '');
}
